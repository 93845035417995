<template>
  <component class="home" :is="pageTemplate" :key="key"></component>
</template>

<script>
import split from "lodash/split";

import category from "~/mixins/category";
import login from "~/mixins/login";
import deliveryReactive from "~/mixins/deliveryReactive";
import categoryTemplate from "@/mixins/categoryTemplate";

import CartInfoList from "@/components/cart/CartInfoList.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";

export default {
  name: "Home",
  mixins: [category, deliveryReactive, login, categoryTemplate],
  components: {
    CartInfoList,
    CategoryTitle
  },
  data() {
    return {
      key: 1
    };
  },
  methods: {
    async reload() {
      let slug = split(this.category.slug, "@")[0];
      await this.resetFilters(slug);
      await this.setCategory({ slug: slug, force: true });

      this.key += 1;
    }
  },
  mounted() {
    global.EventBus.$on("login", () => {
      if (this.$vuetify.breakpoint.xs) {
        this.$vuetify.goTo(0, { duration: 0 });
      }
    });
  }
};
</script>
